import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    meta: { title: "Mieyar" },
    children: [
      {
        path: "/",
        component: () => import("@/views/LandingpageView.vue"),
      },
      {
        path: "/terms_and_conditions",
        component: () => import("@/views/TermsAndConditionsView.vue"),
      },
      {
        path: "/privacy_policy",
        component: () => import("@/views/PrivacyPolicyView.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === "/register") {
    window.location.href = process.env.VUE_APP_BENCHMARK_URL + "/register";
  }
  document.title = to.meta.title || "Mieyar";
  next();
});

export default router;
