<template>
  <div class="container-fluid">
    <router-view />
  </div>
</template>

<script>
import { ApiService } from '@/services/ApiService';

export default {
  name: "App",
  data() {
    return {
      api: new ApiService()
    }
  },
};
</script>
<style>
#app{
    overflow: hidden !important;
}
</style>